.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.margin-auto {
  margin: auto;
}

.cursor-pointer {
  cursor: pointer;
}
