/* width */
::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(200, 200, 200, 0);
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(200, 200, 200, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--white);
  border: 1px solid grey;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: grey;
  border: 1px solid white;
}
