#divLoading {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.2);
  text-align: center;
  color: var(--info);
  display: none;
  z-index: 50100;
}

#divLoading .spinner {
  font-size: 50px;
}

#divLoadingSmall {
  position: fixed;
  text-align: right;
  top: 0px;
  right: 0px;
  color: var(--info);
  display: none;
}
#divLoadingSmall .spinner {
  font-size: 50px;
}
