.application-content {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
}

.__json-string__ {
  /* Specially for JSON viewer on Error log*/
  white-space: normal;
}
